/* this component gets rendered immediately after React loads and while next.js
is busy fetching the rest of the app; please keep it as light as possible! */
export default function LoadingScreen({ children }) {
  return <div style={{
    position: 'fixed',
    top: '20px',
    left: '20px',
    right: '20px',
    bottom: '20px',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }}>
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img
      src='/images/logo-black.svg'
      alt='Padcaster Portal'
      style={{
        width: '480px',
        maxWidth: '100%',
        maxHeight: '86px',
        margin: '0 auto 2rem auto',
      }}
    />
    {children ?? 'Reticulating splines'}
  </div>
}
