import dynamic from 'next/dynamic'
import { Suspense, useState, useEffect } from 'react'

import LoadingScreen from 'components/common/LoadingScreen'

let App = dynamic(() => import('app'), {suspense: true})
import 'styles/globals.scss'


export default function AppLoader(props) {
  // we deploy via `yarn export` and thus can't do any server side rendering
  let [client, setClient] = useState(false)
  useEffect(() => setClient(true), [])
  let loading = <LoadingScreen>Loading, please wait</LoadingScreen>

  return client &&
    <Suspense fallback={loading}>
      <App {...props}/>
    </Suspense>
}
